import {MDCRipple} from '@material/ripple/index';
import {MDCTextField} from '@material/textfield/index';
import {MDCFormField} from '@material/form-field';
import {MDCRadio} from '@material/radio';

$(document).ready(function(){
    const buttons = document.querySelectorAll('.mdc-button');
    for (const button of buttons) {
        MDCRipple.attachTo(button)
    }
    const textFields = document.querySelectorAll('.mdc-text-field');
    for (const textField of textFields) {
        const mdcTextField = MDCTextField.attachTo(textField);
        $(textField).data("mdc", mdcTextField);
    }

    const formFields = document.querySelectorAll('.mdc-form-field');
    for (const formField of formFields) {
        MDCFormField.attachTo(formField);
        let radio = $(formField).find('.mdc-radio')[0];
        MDCRadio.attachTo(radio);
        formField.input = radio;
    }

    var playerContainer, youtubePlayer;

    $('#video')[0].load();

    var ua = navigator.userAgent.toLowerCase();
    var isAndroid = ua.indexOf("android") > -1; //&& ua.indexOf("mobile");

    if(!isAndroid) {
        $('#iphone_container img').remove();
    }

    $('#iphone_container img').click(function(){
        this.remove();
        $('#video')[0].play();
    });

    $('#video')[0].addEventListener('loadeddata', function() {
        $('#video')[0].play();
        if (!$('#video')[0].paused) {
            $('#iphone_container img').remove();
        }
    }, false);

    $('.scroll').click(function(){
        $('#iphone_container img').remove();
        $('#video')[0].play();
    });

    $('#youtube-player').on('webkitfullscreenchange mozfullscreenchange fullscreenchange', function(e) {
        var isFullScreen = document.fullScreen || document.mozFullScreen || document.webkitIsFullScreen;

        var lockOrientation = screen.lockOrientation || screen.mozLockOrientation || screen.msLockOrientation || screen.orientation.lock;
        var unlockOrientation = screen.unlockOrientation || screen.mozUnlockOrientation || screen.msUnlockOrientation || (screen.orientation && screen.orientation.unlock);
        //var src = $('#youtube-player')[0].src.slice(0, -1);

        if (isFullScreen) {
            console.log("lock landscape");
//            $('#youtube-player')[0].src = src + '1'
//            youtubePlayer.setPlaybackQuality('hd1080');
            lockOrientation('landscape');
        } else {
            console.log("registered exit fullscreen and unlocked");
//            $('#youtube-player')[0].src = src + '0'
            unlockOrientation();
        }

    });

    function animateBackground(instrument) {
        var normalScale = {
            '-ms-transform': 'scale(1.0)',
            '-moz-transform': 'scale(1.0)',
            '-webkit-transform': 'scale(1.0)',
            '-o-transform': 'scale(1.0)',
            'transform' : 'scale(1.0)'
        };

        var largeScale = {
            '-ms-transform': 'scale(1.05)',
            '-moz-transform': 'scale(1.05)',
            '-webkit-transform': 'scale(1.05)',
            '-o-transform': 'scale(1.05)',
            'transform' : 'scale(1.05)'
        };

        $('.image-container').fadeOut();

        if (instrument == "ukulele") {
            $('#features').css(largeScale);
        } else {
            $('#features').css(normalScale);
        }

        $('.instrument-btn').attr('disabled','true');

        setTimeout(function(){
            if (instrument == "ukulele") {
                $('.image-container').css('background-image',"url(/assets/ukulele_backdrop-7ad35aeb37a8886d80dce9dc284e011713607ca3ae728d96c62dc96715767bb6.jpg)");
                $('#features').css('background-image',"url(/assets/guitar-rooftop-5b695172da4eae0ce4f4a4dc5eac182e5d57ef35c7c011423174d10e62c458d3.jpg)")
            } else {
                $('.image-container').css('background-image',"url(/assets/guitar-rooftop-5b695172da4eae0ce4f4a4dc5eac182e5d57ef35c7c011423174d10e62c458d3.jpg)");
                $('#features').css('background-image',"url(/assets/ukulele_backdrop-7ad35aeb37a8886d80dce9dc284e011713607ca3ae728d96c62dc96715767bb6.jpg)")
            }
            $('.image-container').show();

            $('.instrument-btn').removeAttr('disabled');
        },600);
    }

    $('.instrument-btn').click(function() {
        $('#iphone_container img').remove();
        var currentInstrument = this.attributes.instrument.value;
        if (currentInstrument == "guitar") {
            this.attributes.instrument.value = "ukulele";
            this.innerText = "GUITAR PLAYER?";
            $('#video source').attr('src','https://d3ogcgbj0d7v63.cloudfront.net/videos/ukulele.mp4');

            $('.highlight-caption').text('Playing ukulele has never been this easy.')

            animateBackground('ukulele');
        } else {
            this.attributes.instrument.value = "guitar";
            this.innerText = "UKULELE PLAYER?";
            $('#video source').attr('src','https://d3ogcgbj0d7v63.cloudfront.net/videos/guitar.mp4');

            $('.highlight-caption').text('Playing guitar has never been this easy.');

            animateBackground('guitar');
        }
        $('#video')[0].load();
    });

    function onPlayerReady(event) {
        playYoutube(event.target);
    }

    function playYoutube(player) {
        if (player.getPlayerState() != YT.PlayerState.PLAYING) {
            player.playVideo();
        }
    }

    $('#play-txt').click(function(){
        if (playerContainer) {
            playYoutube(youtubePlayer);
        }else {
            playerContainer = $('#youtube-player')[0];
            youtubePlayer = new YT.Player('youtube-player', {
                events: {
                    'onReady': onPlayerReady
                }
            });
        }

        var requestFullScreen = playerContainer.requestFullScreen || playerContainer.mozRequestFullScreen || playerContainer.webkitRequestFullScreen;
        if (requestFullScreen) {
            requestFullScreen.bind(playerContainer)();
        }
    });

    $('input[name="os"]').click(function(){

//        if($('input[name="device"]:checked').val() == "ios") {
//            $('#emailTxt').attr("placeholder", "Enter your Apple ID email");
//        }else {
//            $('#emailTxt').attr("placeholder", "Enter your Play Store email");
//        }

    });
});